export const menus = [
  {
    title: 'Conta Digital',
    alt: 'Conta Digital',
    link: '/conta-pj',
  },
  {
    title: 'Cartão',
    alt: 'Cartão',
    link: '/cartao-pj',
  },
]
