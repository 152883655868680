import React from 'react'

export const data = [
  {
    title: 'Conta PJ totalmente digital',
    description: (
      <>
        Você fica livre de taxas de manutenção e pode cancelar sua conta quando
        quiser, sem precisar sair de casa e sem demora
      </>
    ),
    image: 'servico-conta-digital.png',
    link: '/conta-pj-digital',
    btnText: 'Mais sobre a Conta Digital',
    bgColor: '#FCC0CA',
  },
  {
    title: 'Cartão de Débito e Crédito PJ',
    description: (
      <>
        <span>Débito</span> pra facilitar suas compras e <br />
        <span>Crédito</span> pra investir no seu crescimento.
      </>
    ),
    image: 'servico-cartoes.png',
    link: '/cartao-pj',
    btnText: 'Mais sobre os cartões',
    bgColor: '#FDCDD5',
  },
  {
    title: 'Gestão de cobranças fácil',
    description: (
      <>
        Esqueceu de cobrar seus clientes?
        <br />
        <br />
        <span>A gente lembra</span>.
      </>
    ),
    image: 'servico-gestao.png',
    link: 'conta-pj/gestao-de-cobrancas',
    btnText: 'Mais sobre Gestão de cobranças',
    bgColor: '#FDD9DF',
  },
  {
    title: 'Emissão gratuita de boletos',
    description: (
      <>
        <span>Compensa emitir boletos?</span>
        <br />
        <br />
        Emita quantos boletos quiser sem pagar nenhuma taxa usando o app ou desktop. Cobramos apenas R$1,90 por boleto, a partir da 100ª cobrança compensada.
        <br />
        Precisa ir além? Integrações via API para empresas que
        já possuem uma operação mais robusta.
      </>
    ),
    image: 'servico-boleto.png',
    link: '/conta-pj/boletos',
    btnText: 'Mais sobre boletos',
    bgColor: '#FEE6EA',
  },
]
