import React from 'react'

import Copyright from '../../../components/Copyright'
import IconGooglePlay from '../../../components/IconGooglePlay'
import IconAppStore from '../../../components/IconAppStore'

import PolicyPLDFT from '../../../docs/Cora_SCD_Politica_PLDFT.pdf'
import * as Styled from './styled'

const SectionFooter = ({ storeLink }: FooterProps) => (
  <Styled.FooterWrapper>
    <div className="container">
      <Styled.FooterContent>
        <Styled.FooterRow>
          <Styled.ListWrapper>
            <Styled.ListTitle>Cora</Styled.ListTitle>
            <Styled.List>
              <li>
                <Styled.Link
                  href="https://pj.cora.com.br/sala-de-imprensa/"
                  target="_blank"
                >
                  Sala de Imprensa
                </Styled.Link>
              </li>
            </Styled.List>
            <Styled.List>
              <li>
                <Styled.Link
                  href="https://www.cora.com.br/vagas-cora"
                  target="_blank"
                >
                  Carreiras
                </Styled.Link>
              </li>
            </Styled.List>
          </Styled.ListWrapper>

          <Styled.ListWrapper>
            <Styled.ListTitle>Produtos</Styled.ListTitle>
            <Styled.List>
              <li>
                <Styled.Link href="/conta-pj">Conta PJ</Styled.Link>
              </li>
              <li>
                <Styled.Link href="/cartao-pj">Cartão</Styled.Link>
              </li>
              <li>
                <Styled.Link href="/conta-pj/gestao-de-cobranca">
                  Gestão de Cobranças
                </Styled.Link>
              </li>
              <li>
                <Styled.Link href="/conta-pj/boletos">Boletos</Styled.Link>
              </li>
            </Styled.List>
          </Styled.ListWrapper>

          <Styled.ListWrapper>
            <Styled.ListTitle>Explore</Styled.ListTitle>
            <Styled.List>
              <li>
                <Styled.Link href="https://www.cora.com.br/blog/">
                  Blog
                </Styled.Link>
              </li>

              <li>
                <Styled.Link href="https://www.cora.com.br/blog/como-ganhamos-dinheiro/">
                  Porque não cobramos tarifas?
                </Styled.Link>
              </li>
            </Styled.List>
          </Styled.ListWrapper>

          <Styled.ListWrapper>
            <Styled.ListTitle>Suporte</Styled.ListTitle>
            <Styled.List>
              <li>
                <Styled.Link href="http://meajuda.cora.com.br/" target="_blank">
                  Central de Ajuda
                </Styled.Link>
              </li>

              <li>
                <Styled.Link
                  href="https://pj.cora.com.br/seguranca/"
                  target="_blank"
                >
                  Segurança
                </Styled.Link>
              </li>
              <li>
                Contato para Clientes:
                <br />
                <Styled.Link href="tel:1150395715">
                  (11) 5039-5715
                </Styled.Link>
              </li>
              <li>
                <Styled.Link href="tel:08005912350">
                  0800 591 2350
                </Styled.Link>
                <br />
              </li>
              <li>
                <Styled.Link href='https://mobile.cora.com.br/home/chat'>
                  Chat no aplicativo - <Styled.SmallText>Atendimento 24h</Styled.SmallText>
                </Styled.Link>
              </li>
              <li>
                <Styled.Link href="mailto:meajuda@cora.com.br">
                  meajuda@cora.com.br
                </Styled.Link>
              </li>
            </Styled.List>
          </Styled.ListWrapper>
        </Styled.FooterRow>

        <Styled.FooterRow>
          <Styled.ListWrapper className="store-list">
            <Styled.ListTitle className="store-title">
              Baixe o app
            </Styled.ListTitle>

            <Styled.LinkStoresContainer>
              <IconGooglePlay link={storeLink} negative />

              <IconAppStore link={storeLink} negative />
            </Styled.LinkStoresContainer>
          </Styled.ListWrapper>

          <Styled.ListWrapper className="privacy-list">
            <Styled.ListTitle>Transparência</Styled.ListTitle>

            <Styled.List>
              <li>
                <Styled.Link href="/regulatorio">Regulatório</Styled.Link>
              </li>

              <li>
                <Styled.Link href={PolicyPLDFT} target="_blank">
                  Parceiros e Fornecedores
                </Styled.Link>
              </li>

              <li>
                <Styled.Link href="/ouvidoria">Ouvidoria</Styled.Link>
              </li>
            </Styled.List>
          </Styled.ListWrapper>
        </Styled.FooterRow>
      </Styled.FooterContent>
    </div>

    <Styled.FooterCopyright>
      <div className="container">
        <Styled.FooterCopyrightContent>
          <Copyright className="left-content" />
        </Styled.FooterCopyrightContent>
      </div>
    </Styled.FooterCopyright>
  </Styled.FooterWrapper>
)

export default SectionFooter
